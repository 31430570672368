import styled from '@emotion/styled';

import { Link } from '@interness/web-core/src/components/elements';

export const Container = styled.div`
  background-color: var(--chakra-colors-gray-50);
  padding: 40px 30px;
  h3, h4 {
    font-weight: bold;
    font-size: 1.8rem;
  }
  h4 {
    border-top: 1px solid black;
    padding: 15px 0 0 0;
  }
`;

export const Button = styled(Link)`
  background-color: black;
  color: #fff;
  border-radius: 25px;
  text-decoration: none;
  padding: 10px 20px;
  border: 3px solid black;
  &:hover {
    background: #f4f6f7;
    color: unset;
  }
`;