import React                               from 'react';
import { graphql }                         from 'gatsby';
import Img                                 from 'gatsby-image';
import styled                              from '@emotion/styled';
import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai';
import { Heading, Text }                   from '@chakra-ui/react';

import Spacer   from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper  from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel from '@interness/web-core/src/components/media/Carousel/Carousel';
import Masonry  from '@interness/web-core/src/components/media/Masonry/Masonry';

import CTA from '../components/CTA/CTA';
// import Instafeed from '../components/Instafeed/Instafeed';

const OverlayContainer = styled.div`
  position: relative;
  overflow: hidden;
`;
const TextOverlay = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;

  div > * {
    margin: 0;
  }

  h1 {
    font-size: 1rem;
    font-weight: lighter;
    letter-spacing: 4px;
    margin-bottom: 20px;
    @media (max-width: 570px) {
      font-size: 0.8rem;
      font-weight: normal;
    }
    @media (max-width: 380px) {
      font-size: 0.6rem;
    }
  }

  p {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 2px;
    @media (max-width: 720px) {
      font-size: 2rem;
    }
    @media (max-width: 570px) {
      font-size: 1rem;
    }
    @media (max-width: 380px) {
      font-size: 0.8rem;
    }
  }

  a {
    color: #fff;
    margin: 0 10px;

    &:hover {
      color: #FF7062;
    }

    svg {
      height: 42px;
      width: 42px;
      @media (max-width: 570px) {
        margin-top: 10px;
        height: 24px;
        width: 24px;
      }
    }
  }

`;
const TextOverlayCustom = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  p {
    margin: 0;
  }

  p:first-child {
    font-size: 1.4rem;
    letter-spacing: 2px;
    font-weight: lighter;
    color: #FF7062;
    @media (max-width: 720px) {
      font-size: 1rem;
    }
    @media (max-width: 570px) {
      font-weight: normal;
      font-size: 0.8rem;
    }
    @media (max-width: 380px) {
      font-weight: normal;
      font-size: 0.6rem;
    }
  }

  p:last-child {
    font-size: 4rem;
    font-weight: bold;
    @media (max-width: 720px) {
      font-size: 2rem;
    }
    @media (max-width: 570px) {
      font-size: 1rem;
    }
    @media (max-width: 380px) {
      font-size: 0.8rem;
    }
  }
`

const HomePage = (props) => {
  const carouselImages = props.data.images.media;

  const imageOverlays = [
    <>
      <Heading as="h1">ISABELLE PERMANTIER</Heading>
      <Text>Out Now</Text>
      <Text color="brand.500">Kollektion 2023</Text>
    </>,
    <>
      <Heading as="h1">ISABELLE PERMANTIER</Heading>
      <Text>HANDMADE</Text>
      <Text color="brand.500">Kidsfashion & Accessories</Text>
    </>
  ]

  return (
    <>
      <Wrapper>
        <Carousel>
          {carouselImages.map((img, i) => {
            return <OverlayContainer key={img.file.localFile.name}>
              <TextOverlay>
                {imageOverlays[i]}
                <div>
                  {/*eslint-disable-next-line*/}
                  <a href={'https://www.facebook.com/ip.urbankids.3'} target="_blank"
                     rel="noopener noreferrer"><AiFillFacebook/></a>
                  {/* eslint-disable-next-line */}
                  <a href={'https://www.instagram.com/ip_urbankids/'} target="_blank"
                     rel="noopener noreferrer"><AiFillInstagram/></a>
                </div>
              </TextOverlay>
              <Img alt={img.file.localFile.name}
                   fluid={img.file.localFile.childImageSharp.fluid}/>
            </OverlayContainer>;
          })}
        </Carousel>
        <Spacer/>
        <section>
          <Masonry itemsPerRow={[2]}
                   images={props.data.masonry.media.map(({ file }) => ({
                     ...file.localFile.childImageSharp.fluid,
                   }))}/>
        </section>
        <Spacer/>
        <section style={{ width: '100%' }}>
          <OverlayContainer>
            <TextOverlayCustom>
              <div>
                <p>ALLE TEXTILIEN SIND PERSONALISIERBAR</p>
                <p>get your own look</p>
              </div>
            </TextOverlayCustom>
            <Img fluid={props.data.personalisierung.media[0].file.localFile.childImageSharp.fluid}
                 alt={'Personalisierbar'}/>
          </OverlayContainer>
        </section>
        <Spacer/>
        {/*<section style={{ textAlign: 'center' }}>*/}
        {/*  <p style={{ color: '#FF7062', fontWeight: 'light' }}>#KREATIV #FAIR #NACHHALTIG</p>*/}
        {/*  <h2 style={{ fontWeight: 'bold', fontSize: '2rem' }}>be social</h2>*/}
        {/*  <Instafeed/>*/}
        {/*</section>*/}
        <Spacer/>
        {/*eslint-disable-next-line*/}
        <CTA/>
      </Wrapper>
    </>
  )
}

export default HomePage;

export const query = graphql`
    query {
        images: directusMediaCollection(name: {eq: "home-carousel"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1200, maxHeight: 600, quality: 95) {
                                base64
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                sizes
                            }
                        }
                    }
                }
            }
        }
        masonry: directusMediaCollection(name: {eq: "home-masonry"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(quality: 95) {
                                aspectRatio
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        personalisierung: directusMediaCollection(name: {eq: "personalisierung-banner"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1200, maxHeight: 600, quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
