import React         from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { Button }    from '@chakra-ui/react';
import { Link }      from '@interness/web-core/src/components/elements';

import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';

import * as S from './styles';

const CTA = () => {
  return (
    <S.Container>
      <Flex flexWrap="wrap">
        <Box width={['100%', '100%', '30%', '30%']}>
          <h3>Unsere Mission</h3>
          <p>
            Kreativität, Design und höchster Tragekomfort unter Beachtung aller Nachhaltigkeits-, Fairtrade
            und Umweltschutzaspekte, entworfen und gefertigt in liebevoll-fairer Handarbeit in Köln Deutschland/Germany,
            für unsere Welt und alle nachfolgenden Generationen.
          </p>
          <Spacer height={20}/>
          <Button colorScheme='brand' as={Link} to="/kontakt">Schreiben Sie uns</Button>
          <Spacer/>
        </Box>
        <Box width={['100%', '100%', '70%', '70%']}>
          <Flex flexWrap="wrap">
            <Box width={['100%', '100%', '30%', '30%']} px={[5, 5, 20, 20]}>
              <h4>1</h4>
              <p>
                Handmade in Köln Deutschland/Germany, ausschließlich regional eingekaufte Stoffe in
                Bioqualität und Materialen, faire Arbeit, keine langen Transportwege - für eine bessere Umwelt
              </p>
              <Spacer height={30} />
            </Box>
            <Box width={['100%', '100%', '30%', '30%']} px={[5, 5, 20, 20]}>
              <h4>2</h4>
              <p>Alle unsere Artikel sind durch eine hochwertige Stickerei oder durch einen Transferdruck
                personalisierbar. </p>
              <Spacer height={30} />
            </Box>
            <Box width={['100%', '100%', '30%', '30%']} px={[5, 5, 20, 20]}>
              <h4>3</h4>
              <p>Alle verwendeten Materialien und Stoffe sind schadstofffrei und entsprechen dem Oeko-Tex® Standard
                100</p>
              <Spacer height={30} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </S.Container>
  )
};

export default CTA;